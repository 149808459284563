

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styles from "../styles/bioContainer.css"
import Layout from "../components/layout"
import SEO from "../components/seo"

const BioPage = () => (
  <StaticQuery
            
  query = {graphql`
        query BioQuery {
            ci: allStrapiBios (
              filter: {group: {eq: "chiefinvestigators"}}
              sort: {fields: DisplayOrder, order: ASC}
            ) {
              nodes {
                group
                ProfessionalName
                Name
                DisplayOrder
                Biography
                id
                Photo {
                  url
                }
              }
            }
            crctu: allStrapiBios(filter: {group: {eq: "crctuteam"}}
              sort: {fields: DisplayOrder, order: ASC}
            ) {
              nodes {
                group
                ProfessionalName
                Name
                DisplayOrder
                Biography
                id
                Photo {
                  url
                }
              }
            }
            researchteam: allStrapiBios(filter: {group: {eq: "researchteam"}}
              sort: {fields: DisplayOrder, order: ASC}
            ) {
              nodes {
                group
                ProfessionalName
                Name
                DisplayOrder
                Biography
                id
                Photo {
                  url
                }
              }
            }
            pag: allStrapiBios(filter: {group: {eq: "patientadvisorygroup"}}
              sort: {fields: DisplayOrder, order: ASC}
            ) {
              nodes {
                group
                ProfessionalName
                Name
                DisplayOrder
                Biography
                id
                Photo {
                  url
                }
              }
            }
          }

  `}
    
            /* 
              Chief Investigators

              {bio.node.Photo != null &&
                          <img src={bio.node.Photo.url} width="160" class="card_image" alt={bio.node.ProfessionalName} />
                        }

Line 110
                        <div class="card_description">
                            <p>{bio.node.Summary} <a class="button" href={"#popup" + bio.node.DisplayOrder}> Read More</a> </p>
                          </div>
              */
            render={(data) => (
              <div class="cards">
                  <div class="bio_section"> 
                    <div class="bio_title">
                      <h3>Chief Investigators</h3>
                    </div>
                    { data.ci.nodes.map(bio => (
                      <div class="card">
                        <a href={"#popup" + bio.id}>
                        <li>
                            <div class="card_overlay">
                              <div class="card_header">
                                <svg class="card_arc" xmlns="http://www.w3.org/2000/svg"><path /></svg> 
                                {bio.Photo != null &&
                                  <img src={bio.Photo.url} class="card_thumb" alt={bio.ProfessionalName} />
                                }
                                <div class="card_header-text">
                                  {bio.ProfessionalName != null && <h3 class="card_title">{bio.ProfessionalName}</h3>} 
                                  {bio.ProfessionalName == null && <h3 class="card_title">{bio.Name}</h3>}        
                                </div>
                              </div>
                            </div>
                        </li>  
                        </a> 
                        
                        <div id={"popup" + bio.id} class="overlay">
                          
                          <div class="popup">

                          <div class="pop_content">
                            <div class="popup_title"> 
                              {bio.ProfessionalName != null && <h3 class="card_title">{bio.ProfessionalName}</h3>} 
                              <a class="close" href="#">&times;</a>
                            </div> 
                            <div class="popup_col1">
                              {bio.Photo != null &&
                                <img src={bio.Photo.url} class="card_image" alt={bio.ProfessionalName} />
                              }
                            </div>
                            <div class="popup_col2">
                              <p class="bio">{bio.Biography}</p>
                            </div>
                          </div>
                          </div>
                        </div>
                      </div>
                    ))} 
                  </div>



                  <div class="bio_section top">
                    <div class="bio_title">
                      <h3>Research Team</h3>
                    </div>
                    { data.researchteam.nodes.map(bio => (
                      <div class="card">
                        <a href={"#popup" + bio.id}>
                        <li>
                            <div class="card_overlay">
                              <div class="card_header">
                                <svg class="card_arc" xmlns="http://www.w3.org/2000/svg"><path /></svg> 
                                {bio.Photo != null &&
                                  <img src={bio.Photo.url} class="card_thumb" alt={bio.ProfessionalName} />
                                }
                                <div class="card_header-text">
                                  {bio.ProfessionalName != null && <h3 class="card_title">{bio.ProfessionalName}</h3>} 
                                  {bio.ProfessionalName == null && <h3 class="card_title">{bio.Name}</h3>}        
                                </div>
                              </div>
                            </div>
                        </li>  
                        </a> 
                        
                        <div id={"popup" + bio.id} class="overlay">
                          
                          <div class="popup">

                          <div class="pop_content">
                            <div class="popup_title"> 
                              {bio.ProfessionalName != null && <h3 class="card_title">{bio.ProfessionalName}</h3>} 
                              <a class="close" href="#">&times;</a>
                            </div> 
                            <div class="popup_col1">
                              {bio.Photo != null &&
                                <img src={bio.Photo.url} class="card_image" alt={bio.ProfessionalName} />
                              }
                            </div>
                            <div class="popup_col2">
                              <p class="bio">{bio.Biography}</p>
                            </div>
                          </div>
                          </div>
                        </div>
                      </div>
                    ))} 
                  </div>


                  <div class="bio_section top">
                    <div class="bio_title">
                      <h3>CRCTU Team</h3>
                    </div>
                    { data.crctu.nodes.map(bio => (
                      <div class="card">
                        <a href={"#popup" + bio.id}>
                        <li>
                            <div class="card_overlay">
                              <div class="card_header">
                                <svg class="card_arc" xmlns="http://www.w3.org/2000/svg"><path /></svg> 
                                {bio.Photo != null &&
                                  <img src={bio.Photo.url} class="card_thumb" alt={bio.ProfessionalName} />
                                }
                                <div class="card_header-text">
                                  {bio.ProfessionalName != null && <h3 class="card_title">{bio.ProfessionalName}</h3>} 
                                  {bio.ProfessionalName == null && <h3 class="card_title">{bio.Name}</h3>}        
                                </div>
                              </div>
                            </div>
                        </li>  
                        </a> 
                        
                        <div id={"popup" + bio.id} class="overlay">
                          
                          <div class="popup">

                          <div class="pop_content">
                            <div class="popup_title"> 
                              {bio.ProfessionalName != null && <h3 class="card_title">{bio.ProfessionalName}</h3>} 
                              <a class="close" href="#">&times;</a>
                            </div> 
                            <div class="popup_col1">
                              {bio.Photo != null &&
                                <img src={bio.Photo.url} class="card_image" alt={bio.ProfessionalName} />
                              }
                            </div>
                            <div class="popup_col2">
                              <p class="bio">{bio.Biography}</p>
                            </div>
                          </div>
                          </div>
                        </div>
                      </div>
                    ))} 
                  </div>


                  <div class="bio_section top bottom">
                    <div class="bio_title">
                      <h3>Patient Advisory Group</h3>
                        <p>The PETNECK 2 Patient Advisory Group (also known as PAG) is made up of 9 members – 8 Head and Neck Cancer (HNC) survivors and 1 carer.</p>

                    </div>
                    { data.pag.nodes.map(bio => (
                      <div class="card">
                        <a href={"#popup" + bio.id}>
                        <li>
                            <div class="card_overlay">
                              <div class="card_header">
                                <svg class="card_arc" xmlns="http://www.w3.org/2000/svg"><path /></svg> 
                                {bio.Photo != null &&
                                  <img src={bio.Photo.url} class="card_thumb" alt={bio.ProfessionalName} />
                                }
                                <div class="card_header-text">
                                  {bio.ProfessionalName != null && <h3 class="card_title">{bio.ProfessionalName}</h3>} 
                                  {bio.ProfessionalName == null && <h3 class="card_title">{bio.Name}</h3>}        
                                </div>
                              </div>
                            </div>
                        </li>  
                        </a> 
                        
                        <div id={"popup" + bio.id} class="overlay">
                          
                          <div class="popup">

                          <div class="pop_content">
                            <div class="popup_title"> 
                              {bio.ProfessionalName != null && <h3 class="card_title">{bio.ProfessionalName}</h3>} 
                              <a class="close" href="#">&times;</a>
                            </div> 
                            <div class="popup_col1">
                              {bio.Photo != null &&
                                <img src={bio.Photo.url} class="card_image" alt={bio.ProfessionalName} />
                              }
                            </div>
                            <div class="popup_col2">
                              <p class="bio">{bio.Biography}</p>
                            </div>
                          </div>
                          </div>
                        </div>
                      </div>
                    ))} 
                  </div>






                </div>  
            )}

            
  />
)    

export default BioPage;
