import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BioPage from "./biopage"

const AboutPage = ({data:{strapiAbout}} ) => (
  <Layout>
    { strapiAbout.content.map(contentblock => (
      <>
        <div class="content">
          <h2>{contentblock.titleforblock}</h2>
          <p>{contentblock.text}</p>

          <BioPage />
        </div>
      </>
    ))}
  <br/>
  
  </Layout>
)

export const query = graphql` 
  query AboutPageQuery {
    strapiAbout {
      strapiId
      Title
      content {
        titleforblock
        text
      }
      created_at
    }
  }  
`


export default AboutPage
